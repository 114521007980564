import React from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'

export default class EmailSubscribe extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      EMAIL: '',
    }
  }

  submitForm = (e, subscribe) => {
    e.preventDefault()

    if (this.state.EMAIL) {
      subscribe(this.state)
      this.setState({
        EMAIL: '',
      })
    }
  }

  onChangeEmail = e => {
    this.setState({
      ...this.state,
      EMAIL: e.target.value,
    })
  }

  render() {
    return (
      <section className="text-center bg--secondary space--sm">
        <div className="container justify-content-center">
          <h2>Sign up for the newsletter</h2>
          <MailchimpSubscribe
            url="//fb.us19.list-manage.com/subscribe/post?u=c5a279fcb669e6b1539efdff1&id=fb945c58da"
            render={({ subscribe, status, message }) => (
              <form
                className="row justify-content-center form--active"
                style={{marginLeft: 0}}
                // data-success="Thanks for signing up.  Please check your inbox for a confirmation email."
                // data-error="Please provide your email address and agree to the terms."
                onSubmit={e => this.submitForm(e, subscribe)}
              >
                <div className="col-lg-6 col-md-8">
                  <input
                    className="validate-required validate-email"
                    type="email"
                    name="EMAIL"
                    value={this.state.EMAIL}
                    onChange={this.onChangeEmail}
                    placeholder="Enter your email for our monthly newsletter"
                  />
                </div>
                <div className="col-lg-2 col-md-4">
                  {/* add a spacing only visible to xs so the button has margin-top only in mobile screen */}
                  <div className="d-block d-sm-none" style={{margin: '1.85714286em'}} />
                  <button
                    type="submit"
                    style={{marginTop: 0}} // remove marginTop for this case
                    className="btn btn--primary"
                    disabled={status === 'sending'}
                  >
                    Subscribe Now
                  </button>
                </div>
                {/* <div className="col-md-12">
              <div className="input-checkbox">
                <input
                  type="checkbox"
                  className="validate-required"
                  name="group[13737][1]"
                  id="input-assigned-0"
                />
                <label htmlFor="input-assigned-0" />
              </div>
              <span>
                I have read and agree to the{' '}
                <a href="#">terms and conditions</a>
              </span>
            </div> */}

                {/* <div
                  style={{ position: 'absolute', left: '-5000px' }}
                  aria-hidden="true"
                >
                  <input
                    type="text"
                    name="b_77142ece814d3cff52058a51f_f300c9cce8"
                    tabIndex={-1}
                    defaultValue
                  />
                </div> */}
                {status === 'success' && (
                  <div
                    className="col-md-12"
                    style={{ color: 'green' }}
                    dangerouslySetInnerHTML={{ __html: message }}
                  />
                )}
                {status === 'error' && (
                  <div
                    className="col-md-12"
                    style={{ color: 'red' }}
                    dangerouslySetInnerHTML={{ __html: message }}
                  />
                )}
              </form>
            )}
          />
        </div>
      </section>
    )
  }
}

import React from 'react'
// import { Link } from 'gatsby'
import { Events, scroller } from 'react-scroll'

import Layout from '../components/layout'
import VideoTitle from '../components/VideoTitle'
import CarouselFeature from '../components/CarouselFeature'
import TestimonialView from '../components/TestimonialView'
import FeatureImageSection from '../components/FeatureImageSection'
import EmailSubscribe from '../components/EmailSubscribe'
import { kotiImageAltGenerator } from '../utils'
import SEO from '../components/SEO'

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.showMoreRef = React.createRef()
  }

  componentDidMount() {
    Events.scrollEvent.register('begin', function() {
      console.log('begin', arguments)
    })

    Events.scrollEvent.register('end', function() {
      console.log('end', arguments)
    })
  }

  componentWillUnmount() {
    Events.scrollEvent.remove('begin')
    Events.scrollEvent.remove('end')
  }

  onClickShowMore = () => {
    scroller.scrollTo('featureImageSection', {
      duration: 1000,
      delay: 100,
      smooth: true,
      offset: -230, // Scrolls to element + 50 pixels down the page
    })
  }

  render() {
    return (
      <Layout>
        <SEO />
        <VideoTitle onClickShowMore={this.onClickShowMore} />
        <section className="text-center space--sm" ref={this.showMoreRef}>
          <div className="container">
            <div className="row">
              <div className="col-md-10 col-lg-8">
                <h2>We serve multi platforms</h2>
                <p className="lead">
                  Including Google home, Siri, Amazon Alexa and more.
                </p>
              </div>
            </div>
          </div>
        </section>

        <FeatureImageSection />
        <CarouselFeature />
        <section className="switchable feature-large bg--secondary space--xs">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <img
                  alt={kotiImageAltGenerator('Get pampered by concierge')}
                  src={require('../assets/img/koti-concierge.jpg')}
                  className="border--round"
                />
              </div>
              <div className="col-md-6 col-lg-5">
                <div className="switchable__text">
                  <h2>Get pampered by concierge</h2>
                  <p className="lead">
                    Book amenities and facilities around your home. Access the
                    hottest restaurants, shows and events happening around town.
                  </p>
                </div>
              </div>
            </div>
            {/*end of row*/}
          </div>
          {/*end of container*/}
        </section>
        <section className="text-center space--sm">
          <div className="container">
            <div className="row">
              <div className="col-md-10 col-lg-8">
                <h2>How it works</h2>
              </div>
            </div>
          </div>
        </section>
        <section className="switchable">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-6 text-center">
                <img
                  alt={kotiImageAltGenerator('How it works')}
                  className="border--round box-shadow-wide"
                  src={require('../assets/img/cowork-11.jpg')}
                />
              </div>
              <div className="col-md-6 col-lg-5">
                <ol className="process-3">
                  <li className="process_item">
                    <div className="process__number">
                      <span>1</span>
                    </div>
                    <div className="process__body">
                      <h4>Book a free consultation</h4>
                      <p>
                        Select the day and time you would like us to visit you.
                        A Koti expert will help you craft a customized plan for
                        your home.
                      </p>
                    </div>
                  </li>
                  <li className="process_item">
                    <div className="process__number">
                      <span>2</span>
                    </div>
                    <div className="process__body">
                      <h4>Choose what you want</h4>
                      <p>
                        Select from curated brands and products that fit your
                        lifestyle, such as Amazon, Apple and Google.
                      </p>
                    </div>
                  </li>
                  <li className="process_item">
                    <div className="process__number">
                      <span>3</span>
                    </div>
                    <div className="process__body">
                      <h4>No hassle setup</h4>
                      <p>
                        A Koti expert will deliver your products, handle setup
                        and guide you through using each device.
                      </p>
                    </div>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <TestimonialView />
        <EmailSubscribe />
      </Layout>
    )
  }
}

export default IndexPage

import React from 'react'
import { kotiImageAltGenerator } from '../utils';

export default class FeatureImageSection extends React.Component {
  items = [
    {
      title: 'Home Security',
      description:
        'Be at home, even when you are at work or travel. Enjoy the peace of mind.',
      image: require('../assets/img/koti-security.jpg'),
    },
    {
      title: 'Entertainment',
      description: 'Seamless entertainment everywhere in your home.',
      image: require('../assets/img/koti-entertainment.jpg'),
    },
    {
      title: 'Comfort',
      description: 'Lights on, lights off without getting out of bed.',
      image: require('../assets/img/koti-comfort.jpg'),
    },
    {
      title: 'Convenience',
      description:
        'Take a break sometimes off housework. Slow-cook a meal on a busy weeknight.',
      image: require('../assets/img/koti-convenience.jpg'),
    },
  ]

  // constructor(props) {
  //   super(props)
  // }

  render() {
    return (
      <section id="featureImageSection" className="space--xxs">
        <div className="container">
          <div className="row">
            {this.items.map((item, index) => (
              <div className="col-md-6" key={index}>
                <div className="feature">
                  <img
                    alt={kotiImageAltGenerator(item.title)}
                    src={item.image}
                    className="border--round"
                  />
                  <h4>{item.title}</h4>
                  <p className="kotiFeature">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    )
  }
}

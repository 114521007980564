import React from 'react'
import Swiper from 'react-id-swiper'
import { kotiImageAltGenerator } from '../utils';

export default class CarouselFeature extends React.Component {
  items = [
    {
      icon: require('../assets/img/koti-icons/security.svg'),
      title: 'Home security',
      content: (
        <p>
          Show me the front door
          <br />
          Lock the door
        </p>
      ),
    },
    {
      icon: require('../assets/img/koti-icons/entertainment.svg'),
      title: 'Entertainment',
      content: (
        <p>
          Show my photos on TV
          <br />
          Play Toy Story on Netflix
          <br />
          Play some song
        </p>
      ),
    },
    {
      customImgStyle: { marginLeft: 25 },
      icon: require('../assets/img/koti-icons/comfort.svg'),
      title: 'Comfort',
      content: (
        <p>
          Turn on the light
          <br />
          Change the light to light blue
          <br />
          Turn on the AC in the bedroom
        </p>
      ),
    },
    {
      icon: require('../assets/img/koti-icons/convenience.svg'),
      title: 'Convenience',
      content: <p>Ask roomba to start cleaning</p>,
    },
  ]

  // constructor(props) {
  //   super(props)
  // }

  render() {
    const params = {
      slidesPerView: 1,
      grabCursor: true,
      autoplay: {
        delay: 5000,
      },
      loop: true,
      direction: 'horizontal',
      watchOverflow: true,
      spaceBetween: 30,
      ContainerEl: 'div',
      containerClass: 'slides',
      slideClass: 'slide',
      shouldSwiperUpdate: true,
      rebuildOnUpdate: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    }
    return (
      <section className="carouselFeature switchable feature-large bg--secondary space--xs">
        <div className="container">
          <div className="row justify-content-around">
            <div className="col-lg-5 col-md-6 switchable__text">
              <h2>Say it, and magic happens</h2>
              <p className="lead">We Make Home Smart and Simplified</p>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="boxed boxed--lg boxed--border box-shadow-wide">
                <Swiper {...params}>
                  {this.items.map((item, index) => (
                    <div className="col-12" key={index}>
                      <div className="feature feature-3 text-center">
                        {/* <i
                          className={`icon color--primary ${
                            item.icon
                          } icon--lg`}
                        /> */}
                        <img
                          src={item.icon}
                          alt={kotiImageAltGenerator(item.title)}
                          style={{
                            width: 100,
                            height: 100,
                            ...item.customImgStyle,
                          }}
                        />
                        <h4>{item.title}</h4>
                        {item.content}
                        {/* <a href="#">Learn More</a> */}
                      </div>
                    </div>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

import React from 'react'

export default class VideoTitle extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showVideo: false,
    }
  }

  updateDimensions = () => {
    const showVideo = window.screen.width >= 1024

    if (showVideo !== this.state.showVideo){
      this.setState({
        ...this.state,
        showVideo,
      })
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions)
    this.updateDimensions()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  render() {
    return (
      <section
        className="imagebg videobg text-center height-80 video-active"
        data-overlay={4}
      >
        {' '}
        {this.state.showVideo ? (
          <video autoPlay muted poster={require('../assets/video/videobg.jpg')}>
            <source
              src={require('../assets/video/koti.webm')}
              type="video/webm"
            />
            <source
              src={require('../assets/video/koti.mp4')}
              type="video/mp4"
            />
            {/* <source src="video/video.ogv" type="video/ogv" /> */}
          </video>
        ) : null}
        <div
          className="background-image-holder"
          style={{
            backgroundImage: `url("${require('../assets/img/koti-bg.jpg')}")`,
          }}
        >
          {' '}
          {/* <img alt="image" src={require('../assets/img/koti-bg.jpg')} />{' '} */}
        </div>
        <div className="container pos-vertical-center">
          <div className="row">
            <div className="col-md-8 col-lg-7">
              <h1> Make life at home easier</h1>
              <p className="lead">
                {''}
                Choose from curated products that fit your lifestyle and we handle the rest. 
                Koti's voice butler is perfectly incorporated in Google assistant, Alexa and Siri.{' '}
              </p>
              <a
                className="btn btn--primary type--uppercase"
                onClick={this.props.onClickShowMore}
              >
                {' '}
                <span className="btn__text">show me more</span>{' '}
              </a>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
import React from 'react'
import Swiper from 'react-id-swiper'

import './testimonial.css'
import { kotiImageAltGenerator } from '../utils';

export default class TestimonialView extends React.Component {
  items = [
    {
      image: require('../assets/img/koti-testi-judith.jpg'),
      name: 'Judith Lawson',
      description: 'Repulse Bay',
      content:
        'My house rocked! Koti team helps me set up smart devices seamlessly, their secret butler skill is awesome, it helps me get information nearby my place and even play radio that I selected!',
    },
    {
      image: require('../assets/img/koti-testi-peter.jpg'),
      name: 'Peter Chung',
      description: 'Wanchai',
      content:
        "Koti’s localised voice butler is very useful, I could ask it for things I want to get from the region I am living.",
    },
    {
      image: require('../assets/img/koti-testi-laura.jpg'),
      name: 'Laura Oxford',
      description: 'Tin Hau',
      content:
        'My dad has poor eyesight, the voice assistant did help him to get easier living, he could now simply speak to control his house, including lighting, cleaning, asking for time, reading local newspaper from radio... I could also assistant him remotely on phone.',
    },
  ]

  // constructor(props) {
  //   super(props)
  // }

  render() {
    const params = {
      slidesPerView: 1,
      grabCursor: true,
      autoplay: {
        delay: 10*1000,
      },
      loop: true,
      direction: 'horizontal',
      watchOverflow: true,
      spaceBetween: 30,
      // ContainerEl: 'ul',
      // containerClass: 'slides',
      // slideClass: 'slide',
      shouldSwiperUpdate: true,
      rebuildOnUpdate: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.previous',
        prevEl: '.next',
      },
      renderPrevButton: () => (
        <button
          className="kotiCarouselButton previous"
          type="button"
        >
          <svg alt="previous" viewBox="0 0 100 100">
            <path
              d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
              className="arrow"
            />
          </svg>
        </button>
      ),
      renderNextButton: () => (
        <button
          className="kotiCarouselButton next"
          type="button"
        >
          <svg alt="next" viewBox="0 0 100 100">
            <path
              d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
              className="arrow"
              transform="translate(100, 100) rotate(180) "
            />
          </svg>
        </button>
      ),
    }
    return (
      <section className="space--sm">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="slider slider--inline-arrows" data-arrows="true">
                <Swiper {...params}>
                  {this.items.map((item, index) => (
                    <div key={index}>
                      <div className="testimonial row justify-content-center" style={{paddingBottom: 40}}>
                        <div className="col-lg-2 col-md-4 col-6 text-center">
                          <img
                            className="testimonial__image border--round"
                            alt={kotiImageAltGenerator(item.name)}
                            src={item.image}
                          />
                        </div>
                        <div className="col-lg-7 col-md-8 col-12">
                          <span className="h3">“{item.content}”</span>
                          <h5>{item.name}</h5>
                          <span>{item.description}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

module.exports = {
  siteMetadata: {
    siteUrl: `https://koti.ai`,
    title: 'koti | Smart home and voice assistant solution',
    description: "Choose from curated products that fit your lifestyle and we handle the rest. Koti's voice butler is perfectly incorporated in Google assistant, Alexa and Siri."
  },
  // pathPrefix: `/gatsby`,
  plugins: [
    `gatsby-plugin-less`,
    'gatsby-plugin-react-helmet',
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: 'koti',
        short_name: 'koti',
        start_url: '/',
        background_color: '#74c5fd',
        theme_color: '#74c5fd',
        display: 'minimal-ui',
        icon: 'src/images/favicon-o.png', // This path is relative to the root of the site.
      },
    },
    {
      resolve: `gatsby-plugin-google-analytics`,
      options: {
        trackingId: "UA-126901783-1",
        head: true,
      },
    },
    'gatsby-plugin-offline',
    {
      resolve: `gatsby-plugin-sitemap`,
      options: {
        // output: `/some-other-sitemap.xml`,
        // Exclude specific pages or groups of pages using glob parameters
        // See: https://github.com/isaacs/minimatch
        // The example below will exclude the single `path/to/page` and all routes beginning with `category`
        exclude: ["/ihome"]
      }
    }
  ],
}

import path from 'path'
import React from 'react'
import Helmet from 'react-helmet'
import { siteMetadata } from '../../gatsby-config'

const getSchemaOrgJSONLD = ({
  url,
  title,
}) => {
  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url,
      name: title,
      alternateName: siteMetadata.title,
    },
  ]

  return schemaOrgJSONLD
}

const SEO = ({ title, description, image }) => {
  
  const titleString = title || 'koti'
  const descriptionString =
    description || 'Koti re-defines the smart home experience for you. Choose from curated products that fit your lifestyle and we handle the rest.'
  const imageURL = image || require('../assets/img/logo-dark.png')
  const url = 'https://koti.ai'

  const schemaOrgJSONLD = getSchemaOrgJSONLD({
    url,
    titleString
  })

  return (
    <Helmet>
      {/* General tags */}
      <meta name="description" content={descriptionString} />
      <meta name="image" content={imageURL} />

      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      {/* OpenGraph tags */}
      <meta property="og:url" content={url} />
      <meta property="og:title" content={titleString} />
      <meta property="og:description" content={descriptionString} />
      <meta property="og:image" content={imageURL} />
      {/* <meta property="fb:app_id" content={config.fbAppID} /> */}

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      {/* <meta name="twitter:creator" content={config.twitter} /> */}
      <meta name="twitter:title" content={titleString} />
      <meta name="twitter:description" content={descriptionString} />
      <meta name="twitter:image" content={imageURL} />
    </Helmet>
  )
}

export default SEO
